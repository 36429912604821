import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "./Utils/Loader";
import { fetchDataFromApi, Userlogin, Toast } from "./Utils/general-function";
import Header from "./Header";

function AllUpdatedNotification(props) {
  const [loading, setloading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setloading(true);
    const fetchdata = async () => {
      if (!Userlogin()) {
        navigate("/Login");
        return;
      }
      setloading(true);
      try {
        const NewData = await fetchDataFromApi(
          "/api/UpdatedNotificationmaster/AllTodaysUpdatedNotification"
        );
        if (
          NewData.Message === "Authorization has been denied for this request."
        ) {
          localStorage.clear();
          navigate("/Login");
          return;
        }
        setData(NewData.Data);
        setloading(false);
      } catch (error) {
        console.log("Exception found", error);
        setloading(false);
      }
    };
    fetchdata();

    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href =
      "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";

    // Append the link to the document head
    document.head.appendChild(link);

    // Clean up by removing the link when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div className="content">
        <div className="text-center">
          <h2 className="page-title mb-4">New Updates</h2>
        </div>
        <div className="sh-mainbox">
          {data.length === 0 ? (
            <div className="text-center">
              <h6 className="text-danger">
                You're all set! <br />
                There are no new feature updates at the moment.
              </h6>
            </div>
          ) : (
            <div className="accordion" id="accordionExample">
              {data.map((list, i) => (
                <div className="accordion-item rounded-3 border-0 shadow mb-4">
                  <h2 className="accordion-header" id={list.random}>
                    <button
                      className={`accordion-button border-bottom fw-semibold ${i===0?"":"collapsed"}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={"#collapse" + i}
                      aria-expanded={i===0?"true":"false"}
                      aria-controls={"#collapse" + i}
                    >
                      {list.nTitle}
                      {/* New Feature Unlocked! 🛠️ */}
                    </button>
                  </h2>
                  <div
                    id={"collapse" + i}
                    className={`accordion-collapse collapse ${i===0?"show":""}`}
                    aria-labelledby={list.random}
                    data-bs-parent="#accordionExample"
                  >
                    <div>
                      <p className="p-2"> {list.nDescription}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AllUpdatedNotification;
