import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Utils/Loader";
import axios from "axios";

function Leadform(props) {

  const [loading, setLoading] = useState(false);
  const [Saleslist, setSaleslist] = useState([]);
  const username = getCookie("username") === undefined ? "" : getCookie("username");
  const Mobileno = getCookie("Mobileno") === undefined ? "" : getCookie("Mobileno");
  const Salesman = getCookie("salesman") === undefined ? "" : getCookie("salesman");

  const [input, setInput] = useState({ name: username, Mobileno: Mobileno, salesman: Salesman });
  const [errors, setErrors] = useState({});
  let params = useParams();
  const navigate = useNavigate();

  function setCookie(name, value) {
    const date = new Date();
    date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  function getCookie(name) {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
  }
  const handleChange = (e) => {
    let value = e.target.value;
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: value,
    }));
  };
  const Validate = () => {
    let errors = {};
    let isValid = true;
    if (!input["name"]) {
      isValid = false;
      errors["name"] = "This feild is required";
    }
    if (typeof input["name"] !== "undefined") {
      var re = new RegExp(/^[a-zA-Z ]*$/)
      if (!re.test(input["name"])) {
        isValid = false;
        errors["name"] = "Please enter only characters.";
      }
    }
    if (!input["Mobileno"]) {
      isValid = false;
      errors["Mobileno"] = "This feild is required";
    }
    if (typeof input["Mobileno"] !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(input["Mobileno"])) {
        isValid = false;
        errors["Mobileno"] = "Please enter only number.";
      } else if (input["Mobileno"].length !== 10) {
        isValid = false;
        errors["Mobileno"] = "Enter valid mobile number.";
      }
    }
    setErrors(errors);

    return isValid;
  };

  useEffect(() => {

    setLoading(true);
    axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/SalesExecutive/GetSalesExecutiveDetails?dealerrandno=" + params.dealerid)
      .then((res) => {
        setSaleslist(res.data.Data || []);
        setLoading(false)
      })
      .catch((error) => console.log(error)
      )


  }, [])
  const handleSubmit = () => {
    if (Validate()) {
      setLoading(true)
      var param = {
        Name: input.name,
        Contactno: input.Mobileno,
        Carno: params.no,
        nDealerRandno: params.dealerid,
        nRemarks: " ",
        salesexecutiveId: input.salesman ? input.salesman : "0",
        LeadType:"QRLead",
        Modelid:"0",
      };
      const formBody = Object.entries(param).map(
        ([key, value]) => encodeURIComponent(key) + "=" + encodeURIComponent(value)).join("&");
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formBody,
      };
      fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/LeadQRCode/AddLeadQRCode",
        requestOptions
      ).then((response) => response.json())
        .then((res) => {
          setLoading(false);
          setCookie('username', input.name);
          setCookie('Mobileno', input.Mobileno); 
          setCookie('salesman', input.salesman);
          navigate("/CatDetails/" + params.Rand + "/" + params.dealerid + "/W_hB")
        })
    }
  };
  return (
    <div>
      {loading ? <Loader /> : null}
      <div className="content m-4 px-4 pb-4" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", borderRadius: "10px" }}>
        <div className="text-center">
          <h2 className="page-title">Form</h2>
        </div>
        <div className="alert alert-sm alert-danger mt-3" style={{ padding: "8px 12px 10px 12px", fontSize: "13px" }}>
          * Please enter your Name & Mobile No for vehicle details.
        </div>
        {Saleslist.length!==0?
        <div className="form-div">
          <label className="form-label">
            Sales executive
          </label>
          <select
            className="form-select form-control"
            name="salesman"
            value={input.salesman}
            onChange={handleChange}
          >
            <option value="" disable="disable">
              --Select sales executive--
            </option>
            {Saleslist.map((list) => (
              <option value={list.id} key={list.id}>
                {list.Name}
              </option>
            ))}
          </select>
          <span className="text-danger">{errors.salesman}</span>
        </div>:null
        }
        <div className="form-div">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={input.name}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.name}</span>
        </div>

        <div className="form-div">
          <label className="form-label">Mobile No</label>
          <input
            type="text"
            className="form-control"
            name="Mobileno"
            value={input.Mobileno}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.Mobileno}</span>
        </div>
        <div className="text-center pt-3">
          <button type="submit" className="submitbtn" onClick={handleSubmit}>
            Get details
          </button>
        </div>
      </div>
    </div>
  );
}

export default Leadform;
