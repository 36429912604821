import React,{useEffect, useState} from "react";
import Header from "./Header";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function MakeCustomizepost(props) {
const [loading,setLoading]=useState(true)
  useEffect(()=>{
    async function loadscript() {
      const script = document.createElement("script");
      script.src =
        "https://whatsapp.pcubeweb.com/install-widget/bundle.js?key=65e7e2ad-97b0-4755-9cef-59e23cea9889";
      script.defer = true;
      script.setAttribute("data-active", "");
      script.setAttribute("data-widget-type", "group");
      script.setAttribute("data-person", "29da941c-53c3-428f-8efe-c48838f6ca27");

      // Append the script to the document body
      await document.body.appendChild(script);
      setLoading(false);
      // Clean up the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }
    loadscript();
  },[])
  return (
    <div>
      <Header />
      <div className="content">
        <div className="text-center">
          <h2 className="page-title">Design Social Media Post</h2>
        </div>
        <div className="mt-2">
          <label className="text-start mb-2">
            🚗 Attention Used Car Dealers! <br />
            🚗 Elevate your business with our Social Media Poster Services! 🎉
            <br />
            <br />
            🔑 <strong>What We Offer:</strong>
            <br />
            ✔️ 7 to 8 Social Media Images for your used cars
            <br />
            ✔️ Post Writeups to grab attention
            <br />
            ✔️ Direct Posting on your Facebook & Instagram accounts
            <br />
            <br />
            💼 <strong>Why Choose Us?</strong>
            <br />
            ✔️ Showcase your used cars like never before
            <br />
            ✔️ Attract more buyers with professional designs
            <br />
            Save time with ready-to-post content
            <br />
            📈 Boost your online presence and drive sales today! 
            <br /><br/>
            🚘 Here presenting sample of customize post.
            <br/>
          </label>
          <TransformWrapper>
          <TransformComponent>
          <div className="cstmpst text-center">
              <img src="/assets/images/fb_post.jpeg" alt=""  className="me-2"/> <img src="/assets/images/demo1.jpeg" alt="" className="me-2"/>
              <img src="/assets/images/demo2.jpeg" alt="" className="me-2"/>
          </div>
             </TransformComponent> 
              </TransformWrapper>
              <div className="text-center mt-2">
                <label style={{fontSize:"20px"}}><strong>Contact with us for more details</strong></label>
              </div>
          <div data-active id="whatapp-people-widget"></div>

        </div>
      </div>
    </div>
  );
}

export default MakeCustomizepost;
