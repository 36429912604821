import React, { useState,useEffect } from "react";

function Search(props) {
  const [searchtext, setSearchText] = useState("");
  const originalList = props.dataFromParent;
  const basePlaceholder = "Search cars by"; // Static part of the placeholder
  const words = ["transmission", "make", "model", "fuel"]; // Words to animate
  const [displayedPlaceholder, setDisplayedPlaceholder] = useState(`${basePlaceholder} ${words[0]}`); // Initial placeholder
  const [currentWordIndex, setCurrentWordIndex] = useState(0); // Current word index
  const [charIndex, setCharIndex] = useState(0);
  // const Onchange = (e) => {
  //   setSearchText(e.target.value);
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      const currentWord = words[currentWordIndex]; // Get the current word
      const nextChar = currentWord.slice(0, charIndex + 1); // Animate up to the next character

      setDisplayedPlaceholder(`${basePlaceholder} ${nextChar}`); // Update the placeholder
      setCharIndex((prevCharIndex) => {
        if (prevCharIndex + 1 === currentWord.length) {
          // When the full word is displayed, reset charIndex and move to the next word
          setCurrentWordIndex((prevWordIndex) => (prevWordIndex + 1) % words.length);
          return 0; // Reset charIndex for the next word
        }
        return prevCharIndex + 1; // Continue animating the current word
      });
    }, 300); // Animation speed: 300ms per character

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [charIndex, currentWordIndex, words]);
  const OnSearch = (e) => {
    const searchtextt = e.target.value;
    setSearchText(e.target.value);
    let filterdlist = originalList.filter(
      (d) =>
        d.carnumber.toLowerCase().includes(searchtextt.toLowerCase()) ||
        d.companyid.toLowerCase().includes(searchtextt.toLowerCase()) ||
        d.carmodelid.toLowerCase().includes(searchtextt.toLowerCase()) ||
        d.gear.toLowerCase().includes(searchtextt.toLowerCase()) ||
        d.fueltypeid.toLowerCase().includes(searchtextt.toLowerCase())
    );
    props.parentCallback(filterdlist);
  };

  return (
    <div>
      <div className="car-searchbar">
        {" "}
        <button onClick={() => OnSearch()}>
          <img src="assets/images/search-icon.png" alt="" />
        </button>
        <input type="text"  placeholder={displayedPlaceholder} onChange={OnSearch} style={{fontWeight:"500"}} />
      </div>
    </div>
  );
}

export default Search;
