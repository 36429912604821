import React, { useEffect, useState } from 'react';
import Header from './Header'
import Loader from './Utils/Loader'
import { Toast, Userlogin } from './Utils/general-function';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Search from './Search';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { log } from '@tensorflow/tfjs';


function Trashbin(props) {
    const [loading, setLoading] = useState(false);
    const [Data, setData] = useState([]);
    const [originalList, setOriginalList] = useState([]);
    const navigate = useNavigate()

    const header = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        }
    }
    useEffect(() => {
        if (!Userlogin()) {
            navigate("/login")
        }
        setLoading(true);

        try {
            axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/Carmaster/GetCarsinTrash", header)
                .then((resp) => {
                    setData(resp.data.Data);
                    setOriginalList(resp.data.Data)
                    setLoading(false);
                })
        } catch (error) {
            Toast(false, "", error)
        }
    }, [])

    const handleCallback = (childData) => {
        setLoading(true);
        setData(childData);
        setLoading(false);
    };

    const opendeletepopup = (random, action) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                let value = "";
                window.addEventListener("popstate", onClose);
                if (action === "ForDelete") {
                    return (

                        <div className="popup-overlay px-3 py-3 text-center">
                            {/* <p>Are you sure you want to delete this car record?</p> */}
                            <label className="text-danger" style={{ fontWeight: "500" }}>
                                To delete this car permanently, we require your account password.
                            </label>
                            <div className="form-div">
                                <label className="form-label">Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    onChange={(e) => {
                                        value = e.target.value;
                                    }}
                                />
                            </div>
                            <button
                                className="submitbtn me-2 popup-btn"
                                onClick={() => {
                                    OnDelete(random, value);
                                    onClose();
                                }}
                            >
                                Submit
                            </button>
                            <button
                                className="submitbtn me-2 popup-btn"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Close
                            </button>
                        </div>


                    );
                }
                else {
                    return (
                        <div className="popup-overlay px-3 py-3 text-center">
                            <p>Are you sure you want to restore this car?</p>
                            <button
                                className="submitbtn me-2 popup-btn"
                                onClick={() => {
                                    onRestotre(random);
                                    onClose();
                                }}
                            >
                                Yes
                            </button>
                            <button
                                className="submitbtn me-2 popup-btn"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                No
                            </button>
                        </div>
                    )
                }
            },
        });
    };
    const OnDelete = (random, password) => {
        fetch(
            process.env.REACT_APP_API_ENDPOINT +
            "/api/Carmaster/Delete?Rand=" +
            random +
            "&password=" +
            encodeURIComponent(password) + "&action=Fordelete",
            header
        )
            .then((response) => response.json())
            .then((res) => {
                if (res.Success) {
                    let updatelist = Data.filter((d) => d.Rand !== random);
                    setData(updatelist);
                }
                Toast(res.Success, res.Message, res.Message);
            });
    };
    const onRestotre = (random) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/Carmaster/Restorecars?Rand=" + random, header)
            .then((resp) => {
                const res=resp.data
                if(res.Success){
                    let updatelist = Data.filter((d) => d.Rand !== random);
                    setData(updatelist);
                }
                Toast(res.Success, res.Message, res.Message);

            })
    }

    return (
        <div>
            <Header />
            {loading ? <Loader /> : null}

            <div className="content">
                <div className='text-center'>
                    <h2 className="page-title">Trash Bin</h2>
                </div>
                <div className='pt-2'>
                    <p>
                        <span style={{ color: "red" }}>Note : </span> Vehicles remain in Trash for more than 90 days will be automatically deleted.
                    </p>
                </div>
                <Search dataFromParent={originalList} parentCallback={handleCallback} />

                {Data.length === 0 ? <div className='text-center text-danger mt-2 oldcatlg-div'>
                    No records in Trash.
                </div> :

                    Data.map((list) => (
                        <div className="oldcatlg-div" style={{ padding: 0 }} key={list.Rand}>
                            <div className="catlg-img">
                                <a href="#/Catlogue_Detail/dwZfRWs">
                                    <img src={list.carphoto} className="img-fluid" alt="" />
                                </a>
                            </div>
                            <div className="oldcatlg-text mb-2">
                                <h2 className="pt-2">{list.modelyear}{list.companyid} {list.carmodelid} {list.variantid}</h2>
                                <div className="oldcatlg-number">
                                    <span>{list.carnumber}</span>
                                    <span> • {list.km} km</span>
                                    <span> • {list.fueltypeid} </span>
                                    <span> • {list.gear} </span>
                                </div>
                                <div>
                                    <button className="mdbtn border-0 me-2" style={{ backgroundColor: "green" }} onClick={() => opendeletepopup(list.Rand, "ForRestore")}>
                                        Restore
                                    </button>
                                    <button className="mdbtn border-0" onClick={() => opendeletepopup(list.Rand, "ForDelete")}>
                                        Delete
                                    </button>
                                    <div style={{ fontSize: "14px", }}>
                                        <span className="material-symbols-outlined" style={{verticalAlign:"bottom"}}>
                                            auto_delete
                                        </span>
                                        {/* <img src="assets/images/trash-bin.png" alt="" /> */}
                                        <span> {90 - parseInt(list.Carage)} Days</span>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>

        </div>
    );
}

export default Trashbin;