import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Utils/Loader";
import { toast } from "react-toastify";

const useRequestQueue = () => {
  const requestQueue = useRef({}); // To store requests

  const fetchRequest = async (url, params = {}) => {
    const requestKey = JSON.stringify({ url, ...params }); // Create a unique key based on the URL and parameters

    // If the request already exists, return the existing promise
    if (requestQueue.current[requestKey]) {
      return requestQueue.current[requestKey];
    }

    // Create a new request and store the promise
    const requestPromise = fetch(url, { ...params })
      .then((response) => response.json())
      .then((data) => {
        // Remove the request from the queue once resolved
        delete requestQueue.current[requestKey];
        return data;
      })
      .catch((error) => {
        // Handle error and remove the request from the queue
        delete requestQueue.current[requestKey];
        throw error;
      });

    // Store the promise in the queue
    requestQueue.current[requestKey] = requestPromise;

    return requestPromise;
  };

  return { fetchRequest };
};
function ForgetPassword(props) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [input, setInput] = useState({});
  const navigate = useNavigate();
  const { fetchRequest } = useRequestQueue();
  const handleChange = (e) => {
    let value = e.target.value;
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: value,
    }));
  };
  const Validate = () => {
    let errors = {};
    let isValid = true;

    if (!show) {
      if (!input["Mobileno"]) {
        isValid = false;
        errors["Mobileno"] = "This feild is required";
      }
      if (typeof input["Mobileno"] !== "undefined") {
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (!pattern.test(input["Mobileno"])) {
          isValid = false;
          errors["Mobileno"] = "Please enter only number.";
        } else if (input["Mobileno"].length !== 10) {
          isValid = false;
          errors["Mobileno"] = "Enter valid mobile number.";
        }
      }
    } else {
      if (!input["otp"]) {
        isValid = false;
        errors["otp"] = "This feild is required";
      }
    }
    //
    setErrors(errors);

    return isValid;
  };
  const handleSubmit = async () => {
    if (Validate()) {
       setLoading(true);
      const div = document.getElementById("Usersection");
      const param = "Mobileno=" + encodeURIComponent(input.Mobileno);

      const option = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: param,
      };
      const result = await fetchRequest(
        process.env.REACT_APP_API_ENDPOINT +"/api/ForgotPassword/SendOtpforgotpassword",option);
      if (result.Success === true) {
        div.style.display = "none";
       setShow(true);
     } else {
       errors.Mobileno = "User not exist.";
       setErrors(errors);
     }
     setLoading(false);
      // fetch(process.env.REACT_APP_API_ENDPOINT +"/api/ForgotPassword/SendOtpforgotpassword",option)
      //   .then((response) => response.json())
      //   .then((res) => {
      //     if (res.Success === true) {
      //        div.style.display = "none";
      //       //setShow(true);
      //     } else {
      //       errors.Mobileno = "User not exist.";
      //       setErrors(errors);
      //     }

      //     setLoading(false);
      //   });
    }
  };

  const verifyotp = () => {
    if (Validate()) {
      setLoading(true);
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/ForgotPassword/VerifyUserOTP?mobileno=" +
          input.Mobileno +
          "&UserinputOTP=" +
          input.otp
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.Success === true) {
            toast.success("OTP Verified Successfully.");
            navigate("/NewPassword", { state: { key: res.Data } });
          }
          errors.otp = "Entered OTP is not valid.";
          setErrors(errors);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      {loading ? <Loader /> : null}{" "}
      <div className="text-center pt-3 logo-div">
        <img src="assets/images/logo.png" className="logo-img" alt="" />
        <img
          src="assets/images/logo.png"
          className="logo-img logo-imgabs"
          alt=""
        />
      </div>
      <div
        className="content m-4 px-4 pb-4"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          borderRadius: "10px",
        }}
      >
        <div id="Usersection">
          <div className="text-center">
            <h2 className="page-title">Forget Password </h2>
          </div>
          {/* <div className="alert alert-sm alert-danger mt-3" style={{padding : "8px 12px 10px 12px", fontSize: "13px"}}>
          * Please enter your Name & Mobile No for car details.
        </div> */}

          <div className="form-div">
            <label className="form-label">Mobile No</label>
            <input
              type="text"
              className="form-control"
              name="Mobileno"
              onChange={handleChange}
            />
            <span className="text-danger">{errors.Mobileno}</span>
          </div>
          <div className="text-center pt-3">
            <button type="submit" className="submitbtn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>

        <div></div>
        {show && (
          <div id="otpsection">
            <div className="text-center">
              <h2 className="page-title">OTP Verification </h2>
            </div>
            <div className="text-center form-div">
              <label className="form-label mb-0 text-primary">
                Please Check Your WhatsApp for OTP
              </label>
            </div>

            <div className="form-div">
              <label className="form-label">OTP</label>
              <input
                type="text"
                className="form-control"
                name="otp"
                onChange={handleChange}
              />
              <span className="text-danger">{errors.otp}</span>
            </div>

            <div className="text-center pt-3">
              <button type="submit" className="submitbtn" onClick={verifyotp}>
                Verify OTP
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ForgetPassword;
