import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import { Userlogin } from "../Utils/general-function";
import Finance from "tvm-financejs";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
export const LoanEmi = () => {
  const [inputVal, setInput] = useState({
    interestRate: 7,
    loanTenure: 3,
    loanAmount: 100000,
    show: false,
  });
  const [errors, setErrors] = useState({});

  // GLOBAL VARIABLE
  const navigate = useNavigate();
  const financejs = new Finance();

  //METHOD
  useEffect(() => {
    if (!Userlogin()) navigate("/Login");
    onSubmit(inputVal);
  }, []);

  let onRangeChange = async (valAr, name) => {
    
    //
    setInput((preVal) => ({ ...preVal, [name]: valAr[1] }));

    let latestVal = {
      ...inputVal,
      [name]: valAr[1],
    };
    onSubmit(latestVal);
  };
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((preVal) => ({ ...preVal, [name]: value }));
    let latestVal = {
      ...inputVal,
      [name]: value,
    };
    onSubmit(latestVal);
  };


  const onSubmit = (latestVal) => {
    let ratePF = parseFloat(latestVal.interestRate || 0) / 100 / 12; //monthly rate
    let months = parseFloat(latestVal.loanTenure || 0) * 12; // total months
    let loanAmtPF = parseFloat(latestVal.loanAmount || 0);
    let emi = financejs.PMT(ratePF, months, -loanAmtPF, 0, 0);
    let totalPayment = emi * months;
    let totalInterest = emi * months - loanAmtPF;

    setInput((prevVal) => ({
      ...prevVal,
      monthlyEmi: emi,
      totalPayment,
      totalInterest,
      // show: true,
    }));
  };
  const rupeeFormat = (rs) =>
    rs.toLocaleString("en-IN", {
      maximumFractionDigits: 0,
      style: "currency",
      currency: "INR",
    });

  return (
    <div className="parentBoxShadow">
      <Header />
      <div className="text-center">
        <h2 className="page-title">EMI Calculation</h2>
      </div>
      <div className="d-flex flex-column result">
        <div
          className="parentBoxShadow d-flex justify-content-between"
          style={{ borderBottom: "2px solid #fff" }}
        >
          <p>Monthly Payment</p>
          <p className="textRed ">{rupeeFormat(inputVal.monthlyEmi || 0)}</p>
        </div>
        <div className="parentBoxShadow text-center">
          <div className="d-flex justify-content-between">
            <p>Total Payment</p>
            <p className="textRed">{rupeeFormat(inputVal.totalPayment || 0)}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Total Interest Amount </p>
            <p className="textRed">
              {rupeeFormat(inputVal.totalInterest || 0)}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div style={{ margin: "12px 0px" }}>
          <div className="form-div">
            <div className="d-flex justify-content-between mb-3">
              <label className="form-label">Loan Amount (₹) </label>
              <input
                type="number"
                min="100000"
                max="3000000"
                step="50000"
                className="range-slider-value-text range-slider-text2"
                name="loanAmount"
                value={inputVal.loanAmount}
                onChange={onInputChange}
              />
            </div>
            <RangeSlider
              min="100000"
              max="3000000"
              step="50000"
              value={[100000, inputVal.loanAmount]}
              className="single-thumb"
              onInput={(e) => onRangeChange(e, "loanAmount")}
            />
          </div>
          <div className="form-div">
            <div className="d-flex justify-content-between mb-3">
              <label className="form-label">Interest rate (%)</label>
              <span className="range-slider-value">
                <input
                  type="number"
                  name="interestRate"
                  autoComplete="off"
                  className="range-slider-value-text range-slider-text1"
                  value={inputVal.interestRate}
                  onChange={onInputChange}
                  min="7"
                  max="20"
                />
              </span>
            </div>
            <div className="percetageControl">
              <RangeSlider
                min="7"
                max="20"
                step={0.1}
                value={[7, inputVal.interestRate]}
                className="single-thumb"
                onInput={(e) => onRangeChange(e, "interestRate")}
              />
            </div>
          </div>
          <div className="form-div">
            <div className="d-flex justify-content-between mb-3">
              <label className="form-label">Loan Tenure (Yr) </label>
              <span className="range-slider-value">
                <input
                  type="number"
                  autoComplete="off"
                  className="range-slider-value-text range-slider-text1"
                  value={inputVal.loanTenure}
                  min="3"
                  max="20"
                  name="loanTenure"
                  onChange={onInputChange}
                />
              </span>
            </div>

            <div className="percetageControl">
              <RangeSlider
                min="3"
                max="20"
                step={0.1}
                value={[3, inputVal.loanTenure]}
                className="single-thumb"
                onInput={(e) => onRangeChange(e, "loanTenure")}
              />
            </div>
            <span className="text-danger">{errors.loanTenure}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
