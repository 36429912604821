import React,{useState,useEffect,} from 'react';
import { useNavigate } from "react-router-dom";
import {
    generateownerist,
    fetchDataFromApi,
    Userlogin,Toast
  } from "../Utils/general-function";
  import Loader from "../Utils/Loader";
  import { confirmAlert } from "react-confirm-alert";

function BuyerSellereport(props) {
  const [stock, setStock] = useState([]);
  const[loading,setLoading]=useState(true)
  const navigate = useNavigate();
  const header = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
    },
  };
  useEffect(() => {
    const fetchdata = async () => {
      if (!Userlogin()) {
        navigate("/Login");
        return;
      }
      try {
        const cardata = await fetchDataFromApi(
          "/api/BuyerSeller/BuyerSellerTransaction"
        );
      
        if (
          cardata.Message === "Authorization has been denied for this request."
        ) {
          localStorage.clear();
          navigate("/Login");
          return;
        }
        setStock(cardata.Data);
         setLoading(false);
      } catch (error) {
        console.log("Exception found", error);
        setLoading(false);
      }
    };

    fetchdata();
  }, []);

  const openeditpopup = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        let value = "";
        window.addEventListener(("popstate"),onClose)
        return (
          <div className="popup-overlay px-3 py-3 text-center">
            {/* <p>Are you sure you want to delete this car record?</p> */}
            <label className="text-danger" style={{ fontWeight: "500" }}>
              To Download this report, we require your account password.
            </label>
            <div className="form-div">
              <label className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                onChange={(e) => {
                  value = e.target.value;
                }}
              />
            </div>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                 OnSubmitpsw(value);
                onClose();
              }}
            >
              Submit
            </button>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </button>
          </div>
        );
      },
    });
  };
  const OnSubmitpsw=(password)=>{
    setLoading(true);
    fetch(process.env.REACT_APP_API_ENDPOINT+"/api/BuyerSeller/PasswordVerifyforEditBuyerSeller?password="+password,header)
    .then((response)=>response.json())
    .then((res)=>{
     if (res.Success) {
      generateownerist(stock);
     }
     else{
       Toast(res.Success,"",res.Message)
     }
     setLoading(false);
     
    })
   }

  return (
    <div>
    {loading? <Loader/>:null}
   <div className="car-docsdiv">
     <div className="d-flex align-items-center flex-nowrap">
       <img src="assets/images/car-doc.png" alt="" />
       <div className="flex-grow-1 px-3">
         <h3>Buyer/Seller Report</h3>
       </div>
       <div className="d-inline-flex flex-nowrap">
         <button
           className="cardoc-btn"
           onClick={(e) =>openeditpopup()}>
           <img src="assets/images/download-icon.svg" alt="Download"  style={{ width: "32px" }}/>
         </button>
       </div>
     </div>
   </div>
 </div>
    );
}

export default BuyerSellereport;